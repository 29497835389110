<template>
  <div class="form-preview">
    <v-card
      rounded="lg"
      class="pa-9"
      :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
    >
      <div v-if="isApproveOpt" class="mb-4">
        <h3>Surat Pernyataan Persetujuan Menjadi Mahasiswa</h3>
        <v-row no-gutters>
          <v-col
            cols="3"
            sm="3"
            md="3"
            lg="2"
          >
            Status
          </v-col>
          <v-col
            cols="9"
            sm="9"
            md="9"
            lg="10"
          >
            : {{studentAgreementLetter}}
          </v-col>
        </v-row>
        <v-btn 
          color="primary"
          :min-width="120"
          class="mt-4"
          large
          @click="downloadLetter()"
        >
          UNDUH
        </v-btn>
      </div>
      <div class="mb-4" v-if="interviewDetail.length > 0">
        <h3>Detail Test dan Wawancara</h3>
        <p v-html="interviewDetail"></p>
      </div>
      <div class="mb-4" v-if="lecturerNotes.length > 0">
        <h3>Penilaian Dosen</h3>
        <div v-for="itm in lecturerNotes" :key="itm.attr">
          <h4>{{itm.user_name}}</h4>
          <v-row no-gutters>
            <v-col
              cols="3"
              sm="3"
              md="3"
              lg="2"
            >
              Komentar
            </v-col>
            <v-col
              cols="9"
              sm="9"
              md="9"
              lg="10"
            >
              <p v-if="itm.note" v-html="itm.note"></p>
              <p v-else>-</p>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="mb-4">
        <h3>Formulir Pendaftaran</h3>
        <pdf-tabs />
      </div>
      <v-divider></v-divider>
      <div class="my-4">
        <h3>Formulir Kesehatan</h3>
        <p>Silakan cetak formulir kesehatan yang sudah diisi oleh calon mahasiswa.</p>
        <v-btn 
          color="primary"
          :min-width="120"
          class="my-4"
          large
          :disabled="!healthFormUrl"
          @click="downloadFile('healthy')"
        >
          CETAK
        </v-btn>
        <!-- <div class="mt-4 ">
          <v-row no-gutters>
            <v-col
              v-for="img in healthRelatedFileUrl" 
              :key="img.attr"
              class="px-2 py-4"
              cols="12"
              sm="4"
              md="4"
              lg="4"
            >
              <div class="form-preview__health-img">
                <img width="600" height="400" :src="img.url" :alt="`health-img-${img.attr}`">
              </div>
              <v-btn 
                color="primary"
                :min-width="120"
                @click="downloadFile('file', img.url)"
              >
                CETAK
              </v-btn>
            </v-col>
          </v-row>
        </div> -->
        <v-divider></v-divider>
        <div class="my-4">
          <h3>Formulir Dukungan Finansial</h3>
          <p>Silakan cetak formulir dukungan finansial yang sudah diisi oleh calon mahasiswa.</p>
          <v-btn 
            color="primary"
            :min-width="120"
            class="mt-4"
            large
            :disabled="!financialFormUrl"
            @click="downloadFile('financial')"
          >
            CETAK
          </v-btn>
        </div>
        <v-divider></v-divider>
        <div class="my-4">
          <div v-for="item in fileData" :key="item.title">
            <h3>{{item.title}}</h3>
            <div v-if="item.type === 'pict'" class="form-preview__file-img">
              <img v-if="item.url" width="600" height="400" :src="item.url" :alt="`health-img-${item.title}`">
              <p v-else>Data belum di terima</p>
            </div>
            <p v-else>Silakan cetak data yang sudah dikumpulkan oleh calon mahasiswa.</p>
            <v-btn 
              color="primary"
              :min-width="120"
              class="my-4"
              large
              :disabled="!item.url"
              @click="downloadFile('file', item.url)"
            >
              CETAK
            </v-btn>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="my-4">
          <div>
            <h3>Bukti Pembayaran</h3>
            <div v-if="paymentDetail" class="form-preview__file-img">
              <img width="600" height="400" :src="paymentDetail" alt="payment-prooft">
            </div>
            <p v-else>Data belum di terima</p>
            <v-btn 
              color="primary"
              :min-width="120"
              class="my-4"
              large
              :disabled="!paymentDetail"
              @click="downloadFile('file', paymentDetail)"
            >
              CETAK
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { get } from 'dot-prop'
import {
  INIT,
  FORM_PENDING,
  FORM_COMPLETE,
  VALIDATE_PENDING,
  VALIDATE_COMPLETE,
  PASSED,
  STUDENT_AGREE,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'
import PdfTabs from '../pdf/pdfTabs.vue'
export default {
  name: 'FormPreview',
  components: { 
    PdfTabs,
  },
  computed: {
    status () {
      return get(this.$store.state, 'status', INIT)
    },
    isApproveOpt () {
      const opt = [PASSED, STUDENT_AGREE, ACCEPTED, ALUMNI, DROPOUT]
      return opt.indexOf(this.status) !== -1
    },
    studentAgreementLetter () {
      const studentAgreementData = get(this.$store.state, 'student_agreement_letter', {})
      return studentAgreementData.path ? 'Telah diterima' : 'Belum diterima'
    },
    healthFormUrl () {
      return get(this.$store.state, 'health_form.health_form_url', '')
    },
    healthRelatedFileUrl () {
      return get(this.$store.state, 'health_form.health_related_file_url', [])
    },
    financialFormUrl () {
      return get(this.$store.state, 'financial_form.financial_support_url', '')
    },
    formCompleteness () {
      return get(this.$store.state, 'completeness_data_form', {})
    },
    lecturerNotes () {
      return get(this.$store.state, 'lecturer_notes', [])
    },
    interviewDetail () {
      return get(this.$store.state, 'interview', '')
    },
    paymentDetail () {
      return get(this.$store.state, 'payment_form.receipt_payment_url', '')
    },
    fileData () {
      let transcriptUrl = get(this.formCompleteness, 'academic_transcript_url', [])
      if (typeof transcriptUrl === 'string') {
        transcriptUrl = [{
          attr: 'attr1',
          url: transcriptUrl
        }]
      }
      const transcript = []
      transcriptUrl.forEach((itm, idx) => {
        transcript.push({
          title: `Foto transkip nilai yang telah dilegalisir dari pendidikan terakhir ${idx + 1}`,
          type: 'pict',
          url: itm.url
        })
      })
      return [{
        title: 'Pasfoto Berwarna',
        type: 'pict',
        url: get(this.formCompleteness, 'self_pict_url', '')
      // }, {
      //   title: 'Foto Akta Lahir',
      //   type: 'pict',
      //   url: get(this.formCompleteness, 'birth_certificate_url', '')
      }, {
        title: 'Foto KTP/ KK',
        type: 'pict',
        url: get(this.formCompleteness, 'id_card_url', '')
      }, {
        title: 'Foto ijazah yang telah dilegalisir dari pendidikan terakhir',
        type: 'pict',
        url: get(this.formCompleteness, 'education_certificate_url', '')
      }, 
      ...transcript,
      {
        title: 'Foto Surat Baptis Dewasa atau Sidi',
        type: 'pict',
        url: get(this.formCompleteness, 'baptism_certificate_url', '')
      }, {
        title: 'Surat Keterangan dari Gereja atau Lembaga Pengutus',
        type: 'file',
        url: get(this.formCompleteness, 'institution_decription_url', '')
      }, {
        title: 'Sertifikat TOEFL/ IELTS',
        type: 'file',
        url: get(this.formCompleteness, 'toefl_prediction_url', '')
      }, {
        title: 'Esai',
        type: 'file',
        url: get(this.formCompleteness, 'article_paper_url', '')
      }]
    }
  },
  methods: {
    downloadLetter () {
      const studentAgreementData = get(this.$store.state, 'student_agreement_letter', {})
      window.open(studentAgreementData.path, '_blank')
    },
    downloadFile (type, url='') {
      let path = this.healthFormUrl
      if (type === 'financial') {
        path = this.financialFormUrl
      } else if (type === 'file') {
        path = url
      }
      window.open(path, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-preview {
  &__health-img {
    img {
      width: 100%;
      height: auto;
    }
  }
  &__file-img {
    width: 100%;
    height: 250px;
    overflow: auto;
    img {
      width: auto;
      height: 100%;
    }
  }
}
</style>