<template>
  <div v-if="isValidateOpt" class="dosen-opt">
    <v-alert
      v-if="isReviewFinish"
      outlined
      type="success"
      text
      class="title-14 mt-4"
    >
      Hasil penilaian anda sudah dikirimkan ke Admin STT Amanat Agung.
    </v-alert>
    <v-card
      rounded="lg"
      class="pa-9"
      :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
    >
      <h3 class="mb-4">Penilaian Dosen</h3>
      <v-alert
        dismissible
        type="error"
        v-model="isShowAlert"
        class="mb-4"
      >{{ alertMsg }}</v-alert>
      <template>
        <div class="mb-4">
          <validation-observer v-slot="{ invalid }">
            <v-form id="review-submit" @submit.prevent="handleOnSubmit">
              <div class="general-form">
                <validation-provider
                  name="Penilaian dosen"
                  rules="required"
                >
                  <p class="title-14 mb-2">Berikanlah komentar mengenai Calon Mahasiswa.</p>
                  <ckeditor v-model="note" :editor="editor" @ready="onReady"></ckeditor>
                </validation-provider>
              </div>
              <div v-if="isValidateOpt" class="d-flex justify-end mt-4">
                <v-btn 
                  color="primary"
                  :min-width="160"
                  type="submit"
                  form="review-submit"
                  large
                  :disabled="invalid || !note"
                >
                  KIRIM
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </div>
      </template>
    </v-card>
    <dialog-info ref="dialogInfo" :info="infoEval"/>
    <dialog-confirm ref="dialogConfirm" :info="info" :action="submitReviewLecturer"/>
  </div>
</template>

<script>
import {get} from 'dot-prop'
import { required } from 'vee-validate/dist/rules'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
import {
  API_DASH_LECTURER_NOTE_SUBMIT
} from '@/constants/apis'
import {
  INIT,
  FORM_PENDING,
  FORM_COMPLETE,
  VALIDATE_PENDING,
  VALIDATE_COMPLETE,
  PASSED,
} from '@/constants/status'
import request, {getConfig} from '@/utils/request'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'

export default {
  name: 'DosenOption',
  components: {
    ValidationObserver,
    ValidationProvider,
    DialogConfirm,
    DialogInfo
  },
  data () {
    return {
      editor: DecoupledEditor,
      info: {
        desc: 'Apakah Anda yakin penilaian yang Anda berikan sudah benar?'
      },
      infoEval: {
        title: 'dialog.success',
        desc: 'dialog.success.submit.form',
        btn: 'dialog.success.btn'
      },
      alertMsg: '',
      isShowAlert: false,
      note: '',
      isShowSuccess: false,
    }
  },
  computed: {
    status () {
      return get(this.$store.state, 'status', INIT)
    },
    isValidateOpt () {
      const opt = [INIT, FORM_PENDING, FORM_COMPLETE, VALIDATE_COMPLETE, VALIDATE_PENDING]
      return opt.indexOf(this.status) !== -1
    },
    isReviewFinish () {
      return this.status === PASSED || this.isShowSuccess
    },
    lecturerNotes () {
      return get(this.$store.state, 'lecturer_notes', [])
    },
    userId () {
      const config = getConfig()
      return config.userId
    }
  },
  created () {
    this.lecturerNotes.map(itm => {
      if (itm.user_id === this.userId) {
        this.note = itm.note
        this.note ? this.isShowSuccess = true : ''
        return
      }
    })
  },
  mounted () {
    extend('required', required)
  },
  methods: {
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        )
    },
    handleOnSubmit () {
      this.$refs.dialogConfirm && this.$refs.dialogConfirm.show()
    },
    submitReviewLecturer () {
      const params = {
        entry_form_id: get(this.$route.query, 'formId', ''),
        note: this.note 
      }
      request(API_DASH_LECTURER_NOTE_SUBMIT, params).then(res => {
        if (res.success) {
          this.isShowSuccess = true
          this.reloadData()
        } else {
          this.alertMsg = get(res, 'resultInfo.resultMsg', '')
          this.isShowAlert = true
          setTimeout(() => {
            this.isShowAlert = false
          }, 5000)
        }
      })
    },
    reloadData () {
      this.$refs.dialogInfo && this.$refs.dialogInfo.show()
      this.$emit('reloadData')
    },
  }
}
</script>

<style lang="scss" scoped>
.dosen-opt {
  .full-btn {
    width: -webkit-fill-available;
  }
  .title-14 {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  ::v-deep .ck-editor__editable_inline {
    min-height: 400px;
    border: 1px solid grey;
  }
}
</style>