<template>
  <div class="student-detail">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <v-row no-gutters class="general-form">
            <v-col
              cols="12"
              sm="2"
              md="2"
              lg="2"
              class="general-form__col"
            >
              <div class="mr-4 mb-4">
                <v-img
                  :src="profileImage"
                  width="140"
                  height="auto"
                  lazy-src="@/assets/imgs/icon-profile.png"
                  @error="handleImage($event)"
                ></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="7"
              md="7"
              lg="7"
              class="general-form__col"
            >
              <h2 class="mb-4">{{ 'dash.student.detail.title' | text }}</h2>
              <v-row 
                no-gutters
                class="mb-4"
                v-for="item in detailList"
                :key="item.title"
              >
                <v-col
                  cols="4"
                  sm="4"
                  md="4"
                  lg="3"
                >
                  <p class="title-14">{{ item.title | text }}</p>
                </v-col>
                <v-col
                  cols="8"
                  sm="8"
                  md="8"
                  lg="9"
                >
                  <p>{{ `: ${item.value}` }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              lg="3"
              class="general-form__col"
              v-if="isAdmin"
            >
              <div class="column-btn d-flex justify-end align-end">
                <v-btn 
                  color="primary"
                  :min-width="160"
                  large
                  :disabled="status === 'init'"
                  @click="openForm()"
                >
                  EDIT FORMULIR
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <div>
          <v-row 
            no-gutters
            class="my-4"
          >
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              class="pr-4"
            >
              <admin-option v-if="isAdmin" @reloadData="getDashboardForm"/>
              <dosen-option v-else @reloadData="getDashboardForm"/>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="8"
              lg="8"
            >
              <form-preview />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
    <dialog-info ref="dialogInfo" :info="info" :action="handleBack"></dialog-info>
  </div>
</template>

<script>
// Libs
import { get } from 'dot-prop'
import store from '@/store'

// Constants
import {
  DASH_DETAIL_REGIST,
  FORM_LIST
} from '@/constants/pages'
import { text } from '@/apps/web/constants/text'

// Components
import adminOption from '@/apps/dashboard/components/student/adminOption'
import DosenOption from '@/apps/dashboard/components/student/dosenOption.vue'
import formPreview from '@/apps/dashboard/components/student/formPreview'
import DialogInfo from '@/components/common/DialogInfo'

// Utils
import request, { getConfig } from '@/utils/request'

export default {
  components: { 
    adminOption,
    formPreview,
    DialogInfo,
    DosenOption
  },
  name: 'FormDetail',
  data () {
    return {
      isShowAlert: false,
      alertMsg: '',
      isImgError: false,
      profil: {},
      info: {
        title: 'dialog.fail',
        desc: 'dialog.fail.submit.form',
        btn: 'dialog.success.btn'
      },
    }
  },
  computed: {
    isAdmin () {
      const config = getConfig()
      return config.role === 'admin'
    },
    profileImage () {
      const img = get(this.studentDetail, 'image', '@/assets/imgs/icon-profile.png')
      return this.isImgError ? this.defaultImg : img
    },
    studentDetail () {
      return get(this.$store.state, 'studentDetail', {})
    },
    status () {
      return get(this.$store.state, 'status', 'init')
    },
    detailList () {
      const prodi = get(this.studentDetail, 'program_study', '')
      return [{
        title: 'dash.student.detail.name',
        value: get(this.studentDetail, 'name', '')
      }, {
        title: 'dash.student.detail.email',
        value: get(this.studentDetail, 'email', '')
      }, {
        title: 'dash.student.detail.phone',
        value: get(this.studentDetail, 'phone', '')
      }, {
        title: 'dash.student.detail.prodi',
        value: text[`program.studi.${prodi}.title`]
      }, {
        title: 'dash.student.detail.status',
        value: get(this.studentDetail, 'status_name', '')
      }, {
        title: 'dash.student.detail.academic.year',
        value: get(this.studentDetail, 'season_name', '')
      }, {
        title: 'dash.student.detail.period',
        value: get(this.studentDetail, 'academic_year', '')
      }]
    }
  },
  beforeRouteEnter (to, from, next) {
    const params = {
      entry_form_id: get(to.query, 'formId', ''),
    }
    store.dispatch('getDashboardCompletionForm', params).then(res => {
      next(vm => {
        if (!res.success) {
          vm.$refs.dialogInfo && vm.$refs.dialogInfo.show()
        }
      })
    })
  },
  methods: {
    getDashboardForm () {
      const params = {
        entry_form_id: get(this.$route.query, 'formId', ''),
      }
      store.dispatch('getDashboardCompletionForm', params).then(res => {
        if (!res.success) {
          this.$refs.dialogInfo && this.$refs.dialogInfo.show()
        }
      })
    },
    handleImage (event) {
      this.isImgError = true
    },
    openForm () {
      this.$router.push({
        name: DASH_DETAIL_REGIST, 
        query: {
          periode: get(this.studentDetail, 'academic_year', ''),
          formId: get(this.$route.query, 'formId', ''),
          prodi: get(this.studentDetail, 'program_study', '')
        }
      }).catch(() => {})
    },
    handleBack () {
      this.$router.replace({
        name: FORM_LIST,
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.student-detail {
  .column-btn {
    height: 100%;
  }
  .title-14 {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>