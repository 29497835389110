<template>
  <v-dialog
    class="dialog-approved"
    v-model="dialog"
    max-width="550"
    persistent
  >
    <v-card>
      <div class="px-6 pt-6 text-h6 break-word">
        Apakah Anda yakin menerima mahasiswa sebagai mahasiswa STT Amanat Agung?
      </div>

      <v-card-text>
        <p class="title-14 mt-2 mb-2">Attachement File</p>
        <v-file-input
          outlined
          dense
          accept=".pdf, .docx"
          placeholder="Click here to select your file"
          @change="handleInputFile"
        ></v-file-input>
        <v-alert
          dismissible
          type="error"
          v-model="isShowAlert"
        >{{ alertMsg }}</v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="hide()"
        >
          KEMBALI
        </v-btn>
        <v-btn
          color="primary"
          class="mb-4"
          @click="handleDialog()"
        >
          KIRIM
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {get} from 'dot-prop'
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
import {
  API_DASH_FORM_EVALUATION
} from '@/constants/apis'
import request from '@/utils/request'
export default {
  name: 'DialogRejected',
  props: {
    action: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      dialog: false,
      isHandleDialog: true,
      editor: DecoupledEditor,
      reason: '',
      isShowAlert: false,
      alertMsg: '',
      fileData: ''
    }
  },
  methods: {
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        )
    },
    show () {
      this.dialog = true
    },
    hide () {
      this.dialog = false
    },
    handleInputFile (e) {
      this.fileData = e
    },
    handleDialog() {
      const formData = new FormData();
      formData.append("file", this.fileData);
      formData.append("entry_form_id", get(this.$route.query, 'formId', ''));
      formData.append("type", 'passed');
      request(API_DASH_FORM_EVALUATION, formData, {content: 'multipart/form-data'}).then(res => {
        if (res.success) {
          this.hide()
          this.action()
          this.isHandleDialog = false
        } else {
          this.alertMsg = get(res, 'resultInfo.resultMsg', '')
          this.isShowAlert = true
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-approved {
  .break-word {
    word-break: break-word;
  }
}
.wrapper-editor {
  ::v-deep .ck-editor__editable_inline {
    min-height: 400px;
    border: 1px solid grey;
  }
}
</style>
