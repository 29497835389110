<template>
  <div class="pdf-tabs">
    <v-row v-for="itm in formStatus" :key="itm.type" no-gutters class="my-2">
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="4"
      >
        {{itm.type}}
      </v-col>
      <v-col
        cols="8"
        sm="8"
        md="8"
        lg="8"
      >
        : {{itm.value}}
      </v-col>
    </v-row>
    <template>
      <v-tabs
        v-model="tab"
        align-with-title
        class="mb-4"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="item in items"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
    </template>
    <pdf-content v-if="tab === 0" />
    <pdf-couple-agreement v-if="tab === 1" />
    <pdf-recommendation-a  v-if="tab === 2" />
    <pdf-recommendation-b  v-if="tab === 3" />
    <pdf-recommendation-c  v-if="tab === 4" />
  </div>
</template>

<script>
import { get } from 'dot-prop'
const PdfContent = () => import(/* webpackChunkName: "dash-pdf-content" */ '@/apps/dashboard/components/pdf/pdfContent')
const PdfCoupleAgreement = () => import(/* webpackChunkName: "dash-pdf-couple" */ '@/apps/dashboard/components/pdf/pdfCoupleAgreement')
const PdfRecommendationA = () => import(/* webpackChunkName: "dash-pdf-recommend-a" */ '@/apps/dashboard/components/pdf/pdfRecommendationA')
const PdfRecommendationB = () => import(/* webpackChunkName: "dash-pdf-recommend-b" */ '@/apps/dashboard/components/pdf/pdfRecommendationB')
const PdfRecommendationC = () => import(/* webpackChunkName: "dash-pdf-recommend-c" */ '@/apps/dashboard/components/pdf/pdfRecommendationC')
export default {
  data () {
    return {
      tab: null,
    }
  },
  components: {
    PdfCoupleAgreement,
    PdfContent,
    PdfRecommendationA,
    PdfRecommendationB,
    PdfRecommendationC,
  },
  computed: {
    formStatus () {
      const data = []
      this.items.map(itm => {
        const tempData = {}
        tempData.type = itm
        if (itm === 'Formulir Pendaftaran') {
          tempData.value = this.completionForm.is_registration_form ? 'Telah diterima' : 'Belum diterima'
        } else if (itm === 'Formulir Persetujuan Pasangan') {
          tempData.value = this.completionForm.is_couple_agreement_form ? 'Telah diterima' : 'Belum diterima'
        } else if (itm === 'Rekomendasi A') {
          const recom = this.recommendationResult.filter(obj => obj.type === 'a')[0]
          tempData.value = recom && recom.question_1 ? 'Telah diterima' : 'Belum diterima'
        } else if (itm === 'Rekomendasi B') {
          const recom = this.recommendationResult.filter(obj => obj.type === 'b')[0]
          tempData.value = recom && recom.question_1 ? 'Telah diterima' : 'Belum diterima'
        } else if (itm === 'Rekomendasi C') {
          const recom = this.recommendationResult.filter(obj => obj.type === 'c')[0]
          tempData.value = recom && recom.question_1 ? 'Telah diterima' : 'Belum diterima'
        }
        data.push(tempData)
      })
      return data
    },
    recommendationResult () {
      return get(this.$store.state, 'recommendation_form_result', [])
    },
    completionForm () {
      return get(this.$store.state, 'completionForm', {})
    },
    studentDetail () {
      return get(this.$store.state, 'studentDetail', {})
    },
    programStudy () {
      return get(this.studentDetail, 'program_study', 'sth')
    },
    items () {
      const list = [
        'Formulir Pendaftaran', 
        'Formulir Persetujuan Pasangan',
      ]
      if(['sth', 'mmin', 'sert', 'mth', 'mdiv', 'mthu'].indexOf(this.programStudy) !== -1) {
        list.push('Rekomendasi A')
      }
      if(['sth', 'mth', 'mdiv', 'mthu'].indexOf(this.programStudy) !== -1) {
        list.push('Rekomendasi B')
      }
      if(['mth'].indexOf(this.programStudy) !== -1) {
        list.push('Rekomendasi C')
      }
      return list
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.pdf-tabs {
}
</style>