<template>
  <div class="admin-opt">
    <v-alert
      v-if="isRegistrationFinish"
      outlined
      type="success"
      text
      class="title-14 mt-4"
    >
      Pendaftaran Calon Mahasiswa sudah dinyatakan selesai.
    </v-alert>
    <!-- <v-alert
      v-if="isAlumni"
      outlined
      type="success"
      text
      class="title-14 mt-4"
    >
      Mahasiswa merupakan alumni dari STT Amanat Agung.
    </v-alert>
    <v-alert
      v-if="isDO"
      outlined
      type="error"
      text
      class="title-14 mt-4"
    >
      Mahasiswa telah dinyatakan keluar dari STT Amanat Agung.
    </v-alert> -->
    <v-alert
      v-if="isRegistrationRejected"
      outlined
      type="error"
      text
      class="title-14 mt-4"
    >
      <p v-html="rejectedReason"></p>
    </v-alert>
    <v-alert
      dismissible
      type="error"
      v-model="isShowAlert"
      class="mb-4"
    >{{ alertMsg }}</v-alert>
    <v-card
      v-if="!isRegistrationRejected && !isAlumni && !isDO"
      rounded="lg"
      class="pa-9"
      :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
    >
      <template v-if="isValidateOpt">
        <!-- <div class="mb-4">
          <p>Jika semua data yang diperlukan sudah lengkap, silakan memberikan akses kepada dosen untuk penilaian.</p>
          <v-btn 
            color="primary"
            class="full-btn"
            large
            :disabled="isDisabledBtn"
            @click="goToEvaluationSetting('lecturer')"
          >
            LENGKAPI DATA DOSEN
          </v-btn>
        </div> -->
        <div class="my-4">
          <p>Silakan tentukan tanggal wawancara dan mengirim test kepada calon Mahasiswa.</p>
          <v-btn 
            color="primary"
            class="full-btn"
            large
            :disabled="isDisabledBtn"
            @click="goToEvaluationSetting('interview')"
          >
            LENGKAPI DETAIL WAWANCARA
          </v-btn>
        </div>
        <v-divider></v-divider>
        <v-btn 
          color="primary"
          class="full-btn my-4"
          large
          @click="registrationEvaluation('passed')"
        >
          TERIMA PENDAFTARAN
        </v-btn>
        <v-btn 
          color="primary"
          outlined
          class="full-btn"
          large
          @click="registrationEvaluation('rejected')"
        >
          TOLAK PENDAFTARAN
        </v-btn>
      </template>
      <template v-else-if="isApproveOpt">
        <p v-if="!isRegistrationFinish">Pendaftaran Calon Mahasiswa sudah dinyatakan diterima. Silakan perbarui status apabila surat Pernyataan Persetujuan Menjadi Mahasiswa sudah di terima.</p>
        <v-btn 
          v-if="!isRegistrationFinish"
          color="primary"
          class="full-btn my-4"
          large
          :disabled="status === 'passed'"
          @click="finishEvaluation()"
        >
          PENERIMAAN SELESAI
        </v-btn>
        <p v-if="isRegistrationFinish">Apabila terdapat kendala dalam penerimaan mahasiswa, admin dapat membatalkan penerimaan</p>
        <v-btn 
          color="primary"
          outlined
          class="full-btn"
          large
          @click="registrationEvaluation('rejected')"
        >
          PENERIMAAN DIBATALKAN
        </v-btn>
      </template>
    </v-card>
    <!-- <v-card
      v-if="isRegistrationFinish"
      rounded="lg"
      class="pa-9 mt-4"
      :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
    >
      <template>
        <div class="mb-4">
          <p>Mahasiswa telah selesai menempuh jenjang pendidikan.</p>
          <v-btn 
            color="primary"
            class="full-btn"
            large
            @click="confirmChangeStatus('alumni')"
          >
            MENJADI ALUMNI
          </v-btn>
        </div>
        <div class="my-4">
          <p>Mahasiswa tidak dapat melanjutkan perkuliahan.</p>
          <v-btn 
            color="error"
            class="full-btn"
            large
            @click="confirmChangeStatus('do')"
          >
            DIKELUARKAN
          </v-btn>
        </div>
      </template>
    </v-card> -->
    <v-card
      rounded="lg"
      class="pa-9 mt-4"
      :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
    >
      <template>
        <div class="mb-4">
          <p>Apabila terjadi kesalahan, Admin dapat mengembalikan status pendaftaran mahasiswa menjadi Formulir Lengkap. Tombol akan aktif apabila status pendaftaran sudah Formulir Diterima.</p>
          <v-btn 
            color="primary"
            class="full-btn"
            large
            :disabled="isValidateOpt"
            @click="confirmChangeStatus('revert')"
          >
            FORMULIR LENGKAP
          </v-btn>
        </div>
      </template>
    </v-card>
    <dialog-rejected ref="dialogRejected" :action="reloadData"/>
    <dialog-approved ref="dialogApproved" :action="reloadData"/>
    <dialog-info ref="dialogInfo" :info="infoEval"/>
    <dialog-confirm ref="dialogConfirm" :info="info" :action="submitRegistrationEvaluation"/>
    <dialog-confirm ref="dialogConfirmAlumni" :info="infoAlumni" :action="setStatusToAlumni"/>
    <dialog-confirm ref="dialogConfirmDO" :info="infoDO" :action="setStatusToDO"/>
    <dialog-confirm ref="dialogConfirmRevert" :info="infoRevert" :action="revertRegistration"/>
  </div>
</template>

<script>
import {get} from 'dot-prop'
import {
  API_DASH_FORM_EVALUATION,
  API_DASH_FORM_ACCEPT,
  API_DASH_FORM_ALUMNI,
  API_DASH_FORM_DO,
  API_DASH_REVERT_STATUS
} from '@/constants/apis'
import {
  EVALUATION_SETTING
} from '@/constants/pages'
import {
  INIT,
  FORM_PENDING,
  FORM_COMPLETE,
  VALIDATE_PENDING,
  VALIDATE_COMPLETE,
  PASSED,
  STUDENT_AGREE,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'
import request from '@/utils/request'
import DialogRejected from '@/apps/dashboard/components/student/dialogRejected'
import DialogApproved from '@/apps/dashboard/components/student/dialogApproved'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'

export default {
  name: 'AdminOption',
  components: {
    DialogRejected,
    DialogConfirm,
    DialogInfo,
    DialogApproved
  },
  data () {
    return {
      info: {
        desc: 'Apakah Anda yakin menerima mahasiswa sebagai mahasiswa STT Amanat Agung?'
      },
      infoAlumni: {
        desc: 'Apakah Anda yakin bahwa mahasiswa STT Amanat Agung sudah menyelesaikan studi nya?'
      },
      infoDO: {
        desc: 'Apakah Anda yakin akan mengeluarkan mahasiswa STT Amanat Agung?'
      },
      infoRevert: {
        desc: 'Apakah Anda yakin akan mengembalikan status mahasiswa ke Formulir Lengkap?'
      },
      infoEval: {
        title: 'dialog.success',
        desc: 'dialog.success.evaluation.form',
        btn: 'dialog.success.btn'
      },
      alertMsg: '',
      isShowAlert: false,
      fileData: ''
    }
  },
  computed: {
    status () {
      return get(this.$store.state, 'status', INIT)
    },
    isValidateOpt () {
      const opt = [INIT, FORM_PENDING, FORM_COMPLETE, VALIDATE_COMPLETE, VALIDATE_PENDING]
      return opt.indexOf(this.status) !== -1
    },
    isApproveOpt () {
      const opt = [PASSED, STUDENT_AGREE, ACCEPTED]
      return opt.indexOf(this.status) !== -1
    },
    isDisabledBtn () {
      const opt = [INIT, FORM_PENDING]
      return opt.indexOf(this.status) !== -1
    },
    isRegistrationFinish () {
      return this.status === ACCEPTED
    },
    isRegistrationRejected () {
      return this.status === REJECTED
    },
    isAlumni () {
      return this.status === ALUMNI
    },
    isDO () {
      return this.status === DROPOUT
    },
    rejectedReason () {
      return get(this.$store.state, 'rejected_reason', '')
    }
  },
  methods: {
    handleInputFile (e) {
      this.fileData = e
    },
    confirmChangeStatus (status) {
      if (status === 'alumni') {
        this.$refs.dialogConfirmAlumni && this.$refs.dialogConfirmAlumni.show()
      } else if (status === 'revert') {
        this.$refs.dialogConfirmRevert && this.$refs.dialogConfirmRevert.show()
      } else {
        this.$refs.dialogConfirmDO && this.$refs.dialogConfirmDO.show()
      }
    },
    registrationEvaluation (type) {
      if (type === 'passed') {
        this.$refs.dialogApproved && this.$refs.dialogApproved.show()
      } else {
        this.$refs.dialogRejected && this.$refs.dialogRejected.show()
      }
    },
    setStatusToAlumni () {
      const params = {
        entry_form_id: get(this.$route.query, 'formId', ''),
      }
      request(API_DASH_FORM_ALUMNI, params).then(res => {
        if (res.success) {
          this.reloadData()
        } else {
          this.alertMsg = get(res, 'resultInfo.resultMsg', '')
          this.isShowAlert = true
          setTimeout(() => {
            this.isShowAlert = false
          }, 5000)
        }
      })
    },
    setStatusToDO () {
      const params = {
        entry_form_id: get(this.$route.query, 'formId', ''),
      }
      request(API_DASH_FORM_DO, params).then(res => {
        if (res.success) {
          this.reloadData()
        } else {
          this.alertMsg = get(res, 'resultInfo.resultMsg', '')
          this.isShowAlert = true
          setTimeout(() => {
            this.isShowAlert = false
          }, 5000)
        }
      })
    },
    submitRegistrationEvaluation () {
      const params = {
        entry_form_id: get(this.$route.query, 'formId', ''),
      }
      if(this.isApproveOpt) {
        request(API_DASH_FORM_ACCEPT, params).then(res => {
          if (res.success) {
            this.reloadData()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
            setTimeout(() => {
              this.isShowAlert = false
            }, 5000)
          }
        })
      }
    },
    finishEvaluation () {
      this.$refs.dialogConfirm && this.$refs.dialogConfirm.show()
    },
    reloadData () {
      this.$refs.dialogInfo && this.$refs.dialogInfo.show()
      this.$emit('reloadData')
    },
    goToEvaluationSetting (type) {
      this.$router.push({
        name: EVALUATION_SETTING,
        query: {
          formId: get(this.$route.query, 'formId', ''),
          type
        }
      }).catch(()=>{})
    },
    revertRegistration () {
      const params = {
        entry_form_id: get(this.$route.query, 'formId', ''),
        status: 'form_complete'
      }
      request(API_DASH_REVERT_STATUS, params).then(res => {
        if (res.success) {
          this.reloadData()
        } else {
          this.alertMsg = get(res, 'resultInfo.resultMsg', '')
          this.isShowAlert = true
          setTimeout(() => {
            this.isShowAlert = false
          }, 5000)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-opt {
  .full-btn {
    width: -webkit-fill-available;
  }
  .title-14 {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
}
</style>